export default () => {
    return {
        rewards: [],
        expire_date: 0,
        create_date: 0,
        use_count: 0,
        user_limit: 0,
        total_limit: 0,
        remaining_total_limit: 0,
        id: ""
    }
}